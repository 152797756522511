import { CivilStatusCodes } from "../models";
import { formatNumber, parseDate } from "./react";

export type MutationData = {
  prevValue: string;
  value: string;
  title: string;
};

const AustrittReasonForLeavingOptions = {
  0: "Kündigung",
  1: "Krankheit",
};

const AustrittTerminationOptions = {
  4: "keine Angabe",
  1: "Arbeitnehmer",
  2: "Arbeitgeber",
  3: "Einvernehmlich",
};

const AddressMutationValues = [
  {
    object: "address",
    property: "address1",
    title: "Strasse",
    translation: "app.mutationcard.values.address.address1",
  },
  {
    object: "address",
    property: "address2",
    title: "Strasse 2",
    translation: "app.mutationcard.values.address.address2",
  },
  {
    object: "address",
    property: "address3",
    title: "Strasse 3",
    translation: "app.mutationcard.values.address.address3",
  },
  {
    object: "address",
    property: "address4",
    title: "Strasse 4",
    translation: "app.mutationcard.values.address.address4",
  },
  {
    object: "address",
    property: "zipCode",
    title: "PLZ",
    translation: "app.mutationcard.values.address.zipCode",
  },
  {
    object: "address",
    property: "countryName",
    title: "Land",
    translation: "app.mutationcard.values.address.countryName",
  },
  {
    object: "address",
    property: "city",
    title: "Ort",
    translation: "app.mutationcard.values.address.city",
  },
  {
    object: "address",
    property: "validFrom",
    title: "Gültig ab",
    translation: "app.mutationcard.values.address.validFrom",
    isDate: true,
  },
  {
    object: "address",
    property: "validUntil",
    title: "Gültig bis",
    translation: "app.mutationcard.values.address.validUntil",
    isDate: true,
  },
  {
    object: "address",
    property: "email",
    title: "Email Adresse",
    translation: "app.mutationcard.values.address.email",
  },
  {
    object: "address",
    property: "mobileNumber",
    title: "Mobilnummer",
    translation: "app.mutationcard.values.address.mobileNumber",
  },
  {
    object: "address",
    property: "phoneNumber",
    title: "Telefonnummer",
    translation: "app.mutationcard.values.address.phoneNumber",
  },
  {
    object: "address",
    property: "countryCodeISO",
    title: "ISOCode",
    translation: "app.mutationcard.values.address.countryCodeISO",
  },
];

const AdresskorrekturMutationValues = [
  {
    object: "address",
    property: "validUntil",
    title: "Gültig bis",
    translation: "app.mutationcard.values.address.validUntil",
    isDate: true,
  },
];

const VersicherteninfosMutationValues = [
  {
    object: "person",
    property: "name",
    title: "Name",
    translation: "app.mutationcard.values.person.name",
  },
  {
    object: "person",
    property: "firstname",
    title: "Vorname",
    translation: "app.mutationcard.values.person.firstname",
  },
  {
    object: "person",
    property: "birthDate",
    title: "Geburtsdatum",
    translation: "app.mutationcard.values.person.birthDate",
    isDate: true,
  },
  {
    object: "person",
    property: "socialSecurityNumber",
    title: "AHV-Nummer",
    translation: "app.mutationcard.values.person.socialSecurityNumber",
  },
  {
    object: "employee",
    property: "costCenter",
    title: "Kostenstelle",
    translation: "app.mutationcard.values.employee.costCenter",
  },
  {
    object: "employee",
    property: "insurancePlan",
    title: "Versicherungsplan",
    translation: "app.mutationcard.values.person.insurancePlan",
  },
  {
    object: "person",
    property: "sexName",
    title: "Geschlecht",
    translation: "app.mutationcard.values.person.sexName",
  },
  {
    object: "employee",
    property: "extEmployeeId",
    title: "Personal-Nummer",
    translation: "app.mutationcard.values.employee.extEmployeeId",
  },
  {
    object: "employee",
    property: "entryDate",
    title: "Eintrittsdatum Firma",
    isDate: true,
    translation: "app.mutationcard.values.employee.entryDate",
  },
  {
    object: "person",
    property: "nationalityISO",
    title: "Nationalität",
    translation: "app.mutationcard.values.person.nationalityISO",
  },
];

const PayrollMutationValues = [
  {
    object: "payroll",
    property: "grossSalary",
    title: "Jahresbruttolohn",
    translation: "app.mutationcard.values.payroll.grossSalary",
    isMoney: true,
  },
  {
    object: "payroll",
    property: "activityRate",
    title: "Beschäftigungsgrad",
    translation: "app.mutationcard.values.payroll.activityRate",
  },
  {
    object: "payroll",
    property: "validFrom",
    title: "Gültig ab",
    translation: "app.mutationcard.values.payroll.validFrom",
    isDate: true,
  },
  // {object: "payroll", property: "validUntil", title: "Gültig bis", translation: "app.mutationcard.values.payroll.validUntil", isDate: true}
];

const UnpaidVacationMutationValues = [
  {
    object: "unpaidVacation",
    property: "validFrom",
    title: "Von",
    translation: "app.mutationcard.values.unpaidVacation.validFrom",
    isDate: true,
  },
  {
    object: "unpaidVacation",
    property: "validUntil",
    title: "Bis",
    translation: "app.mutationcard.values.unpaidVacation.validUntil",
    isDate: true,
  },
  {
    object: "unpaidVacation",
    property: "contributionCoverage",
    title: "app.mutationcard.values.person.costCenter",
    translation: "app.mutationcard.values.unpaidVacation.contributionCoverage",
  },
];

const AustrittMutationValues = [
  {
    object: "employee",
    property: "noticeGivenBy",
    title: "Wer hat gekündigt?",
    translation: "app.mutationcard.values.employee.termination",
    options: AustrittTerminationOptions,
  },
  {
    object: "employee",
    property: "reasonForLeaving",
    title: "Austrittsgrund",
    translation: "app.mutationcard.values.employee.reasonForLeaving",
    options: AustrittReasonForLeavingOptions,
  },
  {
    object: "employee",
    property: "employeeCustomizable1",
    title: "Über 58 Jahre alt",
    translation: "app.mutationcard.values.employee.employeeCustomizable1",
  },
  {
    object: "employee",
    property: "terminatedByEmployer",
    title: "Kündigung durch Arbeitgeber",
    translation: "app.mutationcard.values.employee.terminatedByEmployer",
  },
  {
    object: "employee",
    property: "employeeCustomizable3",
    title: "Neuer Arbeitgeber",
    translation: "app.mutationcard.values.employee.employeeCustomizable3",
  },
  {
    object: "employee",
    property: "employeeCustomizable2",
    title: "Kein neuer Arbeitgeber",
    translation: "app.mutationcard.values.employee.employeeCustomizable2",
  },
  {
    object: "employee",
    property: "employeeCustomizable4",
    title: "Neue Pensionskasse",
    translation: "app.mutationcard.values.employee.employeeCustomizable4",
  },
  {
    object: "employee",
    property: "employeeNote",
    title: "Bemerkungen",
    translation: "app.mutationcard.values.employee.employeeNote",
  },
  {
    object: "employee",
    property: "quittingDate",
    title: "Austrittsdatum",
    translation: "app.mutationcard.values.employee.quittingDate",
    isDate: true,
  },
  {
    object: "payoutAddress",
    property: "iban",
    title: "IBAN",
    translation: "app.mutationcard.values.payoutAddress.iban",
  },
  {
    object: "payoutAddress",
    property: "address",
    title: "Strasse",
    translation: "app.mutationcard.values.payoutAddress.address",
  },
  {
    object: "payoutAddress",
    property: "zip",
    title: "Postleitzahl",
    translation: "app.mutationcard.values.payoutAddress.zip",
  },
  {
    object: "payoutAddress",
    property: "city",
    title: "Ort",
    translation: "app.mutationcard.values.payoutAddress.city",
  },
];

const InvaliditatMutationValues = [
  {
    object: "employee",
    property: "degreeDisability",
    title: "Invaliditätsgrad",
    translation: "app.mutationcard.values.employee.degreeDisability",
  },
  {
    object: "employee",
    property: "disabilityDate",
    title: "Invaliditäts Datum",
    translation: "app.mutationcard.values.employee.disabilityDate",
    isDate: true,
  },
];

// comments object via confluence file data does not to be shown
const ZivilstandMutationValues = [
  {
    object: "person",
    property: "civilStatusName",
    title: "Zivilstand",
    translation: "app.mutationcard.values.person.civilStatusName",
  },
  {
    object: "person",
    property: "marriageDate",
    title: "Heiratsdatum",
    translation: "app.mutationcard.values.person.marriageDate",
    isDate: true,
  },
  {
    object: "person",
    property: "divorceDate",
    title: "Scheidungsdatum",
    translation: "app.mutationcard.values.person.divorceDate",
    isDate: true,
  },
  {
    object: "person",
    property: "name",
    title: "Name",
    translation: "app.mutationcard.values.person.name",
  },
  // {object: "person", property: "customizable6", title: "Name Ehepartner/in", translation: "app.mutationcard.values.person.customizable6"},
  // {object: "person", property: "customizable7", title: "Vorname Ehepartner/in", translation: "app.mutationcard.values.person.customizable7"},
  // {object: "person", property: "customizable2", title: "Geburtsdatum Ehepartner/in", translation: "app.mutationcard.values.person.customizable2", isDate: true},
  // {object: "person", property: "customizable8", title: "Name nach Heirat / Scheidung", translation: "app.mutationcard.values.person.customizable8"},
  // {object: "person", property: "personNote", title: "Bemerkungen", translation: "app.mutationcard.values.employee.personNote"},
];

// comments object via confluence file data does not to be shown
const PensionierungMutationValues = [
  {
    object: "employee",
    property: "retirementDate",
    title: "Pensionierungdatum",
    translation: "app.mutationcard.values.employee.retirementDate",
    isDate: true,
  },
  {
    object: "employee",
    property: "degreeRetirement",
    title: "Teilpensionierung",
    translation: "app.mutationcard.values.employee.degreeRetirement",
  },
  // {object: "employee", property: "retirementContactPhoneNumber", title: "Telefonnummer", translation: "app.mutationcard.values.employee.retirementContactPhoneNumber"},
  // {object: "employee", property: "retirementContactMobilePhoneNumber", title: "Mobilnummer", translation: "app.mutationcard.values.employee.retirementContactMobilePhoneNumber"},
  // {object: "employee", property: "retirementContactEmail", title: "Email Adresse", translation: "app.mutationcard.values.employee.retirementContactEmail"},
];

// comments object via confluence file data does not to be shown
const TodesfallMutationValues = [
  {
    object: "person",
    property: "dateOfDeath",
    title: "Todesdatum",
    translation: "app.mutationcard.values.person.dateOfDeath",
    isDate: true,
  },
  // {object: "person", property: "contactName", title: "Name", translation: "app.mutationcard.values.person.contactName"},
  // {object: "person", property: "contactFirstname", title: "Vorname", translation: "app.mutationcard.values.person.contactFirstname"},
  // {object: "person", property: "contactAddress", title: "Adresse", translation: "app.mutationcard.values.person.contactAddress"},
  // {object: "person", property: "contactZipCode", title: "Postleitzahl", translation: "app.mutationcard.values.person.contactZipCode"},
  // {object: "person", property: "contactTown", title: "Ort", translation: "app.mutationcard.values.person.contactTown"},
  // {object: "person", property: "contactCountryName", title: "Land", translation: "app.mutationcard.values.person.contactCountryName"},
  // {object: "person", property: "contactPhoneNumber", title: "Telefonnummer", translation: "app.mutationcard.values.person.contactPhoneNumber"},
  // {object: "person", property: "contactEmail", title: "Email Adresse", translation: "app.mutationcard.values.person.contactEmail"},
];

const UnemployabilityMutationValues = [
  {
    object: "employee",
    property: "unemployabilityDate",
    title: "Datum der Erwerbsunfähigkeit",
    translation: "app.mutationcard.values.employee.unemployabilityDate",
    isDate: true,
  },
  {
    object: "employee",
    property: "degreeUnemployability",
    title: "Erwerbsunfähigkeitsgrad",
    translation: "app.mutationcard.values.employee.degreeUnemployability",
  },
];

// comments object via confluence file data does not to be shown
const EintrittMutationValues = [
  {
    object: "employee",
    property: "entryDate",
    title: "Versicherung Eintrittsdatum",
    translation: "app.mutationcard.values.employee.entryDate",
    isDate: true,
  },

  {
    object: "person",
    property: "name",
    title: "Name",
    translation: "app.mutationcard.values.person.name",
  },
  {
    object: "person",
    property: "firstname",
    title: "Vorname",
    translation: "app.mutationcard.values.person.firstname",
  },
  {
    object: "person",
    property: "birthDate",
    title: "Geburtsdatum",
    translation: "app.mutationcard.values.person.birthDate",
    isDate: true,
  },
  {
    object: "person",
    property: "sexName",
    title: "Geschlecht",
    translation: "app.mutationcard.values.person.sexName",
  },
  {
    object: "person",
    property: "socialSecurityNumber",
    title: "AHV-Nummer",
    translation: "app.mutationcard.values.person.socialSecurityNumber",
  },
  {
    object: "person",
    property: "civilStatusName",
    title: "Zivilstand",
    translation: "app.mutationcard.values.person.civilStatusName",
  },
  {
    object: "person",
    property: "marriageDate",
    title: "Heiratsdatum",
    translation: "app.mutationcard.values.person.marriageDate",
    isDate: true,
  },
  {
    object: "person",
    property: "divorceDate",
    title: "Scheidungsdatum",
    translation: "app.mutationcard.values.person.divorceDate",
    isDate: true,
  },
  {
    object: "person",
    property: "languageName",
    title: "Korrespondenzsprache",
    translation: "app.mutationcard.values.person.languageName",
  },

  // {object: "employee", property: "employerId", title: "Arbeitgeber"},
  {
    object: "employee",
    property: "insurancePlan",
    title: "Versicherungsplan",
    translation: "app.mutationcard.values.employee.insurancePlan",
  },
  // {object: "employee", property: "extEmployeeId", title: "Personal-Nummer", translation: "app.mutationcard.values.employee.Personal-Nummer"},
  // {object: "employee", property: "costCenter", title: "Kostenstelle", translation: "app.mutationcard.values.employee.Kostenstelle"},
  {
    object: "employee",
    property: "entryDate",
    title: "Eintrittsdatum in die Firma",
    translation: "app.mutationcard.values.employee.entryDate",
    isDate: true,
  },

  {
    object: "payroll",
    property: "grossSalary",
    title: "Jahresbruttolohn",
    translation: "app.mutationcard.values.payroll.grossSalary",
    isMoney: true,
  },
  {
    object: "payroll",
    property: "activityRate",
    title: "Beschäftingungsgrad",
    translation: "app.mutationcard.values.payroll.activityRate",
  },
  // {object: "payroll", property: "validFrom", title: "Gültig ab", translation: "app.mutationcard.values.payroll.validFrom", isDate: true},
  // {object: "payroll", property: "payrollNote", title: "Bemerkungen", translation: "app.mutationcard.values.payroll.payrollNote"},

  {
    object: "address",
    property: "address1",
    title: "Adresszeile 1",
    translation: "app.mutationcard.values.address.address1",
  },
  {
    object: "address",
    property: "address2",
    title: "Adresszeile 2",
    translation: "app.mutationcard.values.address.address2",
  },
  {
    object: "address",
    property: "zipCode",
    title: "Ort",
    translation: "app.mutationcard.values.address.zipCode",
  },
  {
    object: "address",
    property: "city",
    title: "Postleitzahl",
    translation: "app.mutationcard.values.address.city",
  },
  {
    object: "address",
    property: "country",
    title: "Land",
    translation: "app.mutationcard.values.address.country",
  },
  {
    object: "address",
    property: "validFrom",
    title: "Gültig ab",
    translation: "app.mutationcard.values.address.validFrom",
    isDate: true,
  },
];

// comments object via confluence file data does not to be shown
const PersonalienanderungMutationValues = [
  {
    object: "person",
    property: "birthDate",
    title: "Geburtsdatum",
    translation: "app.mutationcard.values.person.birthDate",
    isDate: true,
  },
  {
    object: "person",
    property: "firstname",
    title: "Vorname",
    translation: "app.mutationcard.values.person.firstName",
  },
  {
    object: "person",
    property: "name",
    title: "Name",
    translation: "app.mutationcard.values.person.name",
  },
  // {object:"person", property: "salutationCode", title: "Anrede", translation: "app.mutationcard.values.person.salutationCode"},
  {
    object: "person",
    property: "sex",
    title: "Geschlecht",
    translation: "app.mutationcard.values.person.sex",
  },
  {
    object: "person",
    property: "socialSecurityNumber",
    title: "AHV-Nummer",
    translation: "app.mutationcard.values.person.socialSecurityNumber",
  },
  {
    object: "person",
    property: "nationalityISO",
    title: "Nationalität",
    translation: "app.mutationcard.values.person.nationalityISO",
  },
];

// comments object via confluence file data does not to be shown
const AstellungsanderungMutationValues = [
  {
    object: "employee",
    property: "insurancePlan",
    title: "Versicherungsplan",
    translation: "app.mutationcard.values.employee.insurancePlan",
  },
  {
    object: "employee",
    property: "entryDate",
    title: "Firmeneintrittsdatum",
    translation: "app.mutationcard.values.employee.entryDate",
    isDate: true,
  },
  {
    object: "employee",
    property: "extEmployeeId",
    title: "Personalnumer",
    translation: "app.mutationcard.values.employee.extEmployeeId",
  },
  {
    object: "employee",
    property: "costCenter",
    title: "Kostenstelle",
    translation: "app.mutationcard.values.employee.costCenter",
  },
  // {object:"employee", property: "insEmployeeId", title: "Versicherten Nummer", translation: "app.mutationcard.values.employee.insEmployeeId"},
  // {object:"employee", property: "insuranceEntryDate", title: "Versicherungseintrittsdatum", translation: "app.mutationcard.values.employee.insuranceEntryDate"},
];

const MutationValuesToCheck = {
  Informationsänderung: VersicherteninfosMutationValues,
  Adressaenderung: AddressMutationValues,
  Adresslöschung: AddressMutationValues,
  Adresskorrektur: [...AddressMutationValues, ...AdresskorrekturMutationValues],
  Lohnkorrektur: PayrollMutationValues,
  Lohnlöschung: PayrollMutationValues,
  Lohnaenderung: PayrollMutationValues,
  UnbezahlterUrlaublöschung: UnpaidVacationMutationValues,
  UnbezahlterUrlaub: UnpaidVacationMutationValues,
  UnbezahlterUrlaubKorrektur: UnpaidVacationMutationValues,
  Austritt: AustrittMutationValues,
  Austrittskorrektur: AustrittMutationValues,
  Invaliditaet: InvaliditatMutationValues,
  Zivilstandsaenderung: ZivilstandMutationValues,
  Pensionierung: PensionierungMutationValues,
  Todesfall: TodesfallMutationValues,
  Eintritt: EintrittMutationValues,
  Wiedereintritt: EintrittMutationValues,
  Erwerbsunfaehigkeit: UnemployabilityMutationValues,
  Personalienänderung: PersonalienanderungMutationValues,
  Anstellungsänderung: AstellungsanderungMutationValues,
};

export const createMutationContent = (mutation) => {
  const values =
    MutationValuesToCheck[mutation?.attributes?.mutationType?.name];
  if (!values) {
    return { content: [], more: [] };
  }

  // fix partnership label
  if (
    (mutation?.attributes?.mutationType?.name === "Zivilstandsaenderung" ||
      mutation?.attributes?.mutationType?.name === "Eintritt" ||
      mutation?.attributes?.mutationType?.name === "Wiedereintritt") &&
    mutation?.attributes?.values?.person?.civilStatus ===
      CivilStatusCodes.RegisteredPartnership
  ) {
    values.map((value) => {
      if (value.property === "marriageDate") {
        value.title = "Datum eingetragene Partnerschaft";
        value.translation = "app.mutationcard.values.person.partnerschaftdate";
      }
    });
  }

  const content = [];
  const more = [];
  values.forEach((value) => {
    const { object, property, title, isDate, isMoney, options, translation } =
      value;
    let currentMutationValue = mutation.attributes.values?.[object]?.[property];
    let previousMutationValue =
      mutation.attributes.valuesBefore?.[object]?.[property];
    if (isDate) {
      currentMutationValue = parseDate(currentMutationValue);
      previousMutationValue = parseDate(previousMutationValue);
    }
    if (isMoney) {
      currentMutationValue = (
        "CHF " + formatNumber(currentMutationValue)
      ).includes("undefined")
        ? "-"
        : "CHF " + formatNumber(currentMutationValue);
      previousMutationValue = (
        "CHF " + formatNumber(previousMutationValue)
      ).includes("undefined")
        ? "-"
        : "CHF " + formatNumber(previousMutationValue);
    }
    if (typeof currentMutationValue === "boolean") {
      if (currentMutationValue === true) {
        currentMutationValue = "Ja";
      } else {
        currentMutationValue = "Nein";
      }
    }
    if (typeof previousMutationValue === "boolean") {
      if (previousMutationValue === true) {
        previousMutationValue = "Ja";
      } else {
        previousMutationValue = "Nein";
      }
    }
    if (property === "contributionCoverage") {
      currentMutationValue = unpaidVacationContributionValue(
        mutation.attributes.values?.[object]?.[property]
      );
      previousMutationValue = unpaidVacationContributionValue(
        mutation.attributes.valuesBefore?.[object]?.[property]
      );
    }

    if (currentMutationValue != previousMutationValue) {
      let value =
        !(typeof currentMutationValue === "number" || currentMutationValue) ||
        currentMutationValue == "KeinCode"
          ? "-"
          : currentMutationValue;
      let prevValue = previousMutationValue ?? "-";
      if (options) {
        if (property === "noticeGivenBy") {
          value =
            value != "-"
              ? value === 0 || !value
                ? "-"
                : options[value]
              : value;
          prevValue =
            prevValue != "-"
              ? prevValue === 0 || !prevValue
                ? "-"
                : options[prevValue]
              : prevValue;
        } else {
          value = value != "-" ? options[value] : value;
          prevValue = prevValue != "-" ? options[prevValue] : prevValue;
        }
      }
      if (
        property === "activityRate" ||
        property === "degreeRetirement" ||
        property === "degreeUnemployability" ||
        property === "degreeDisability"
      ) {
        value = value !== "-" ? value + "%" : value;
        prevValue = prevValue !== "-" ? prevValue + "%" : prevValue;
      }
      if (!content.length) {
        content.push({
          title,
          value,
          prevValue,
          translation: translation || "default",
        });
      } else {
        more.push({
          title,
          value,
          prevValue,
          translation: translation || "default",
        });
      }
    } else if (mutation?.attributes?.mutationType?.name.includes("löschung")) {
      more.push({
        title,
        value: "-",
        prevValue: previousMutationValue || "-",
        translation: translation || "default",
      });
    }
  });

  return { content, more };
};

export function createMutationContentt(mutation) {
  try {
    switch (mutation?.attributes?.mutationType?.name) {
      case "Informationsänderung":
        return mutationVersicherteninfos(mutation);
      case "Adressaenderung":
        return mutationAdresse(mutation);
      case "Adresskorrektur":
        return mutationAdresse(mutation);
      case "Lohnkorrektur":
        return mutationLohnaenderung(mutation);
      case "Lohnaenderung":
        return mutationLohnaenderung(mutation);
      case "UnbezahlterUrlaub":
        return mutationUnbezahlterUrlaub(mutation);
      case "UnbezahlterUrlaubKorrektur":
        return mutationUnbezahlterUrlaub(mutation);
      case "Austritt":
        return mutationAustritt(mutation);
      case "Austrittskorrektur":
        return mutationAustritt(mutation);
      case "Invaliditaet":
        return mutationInvaliditat(mutation);
      case "Zivilstandsaenderung":
        return mutationZivilstandsanderung(mutation);
      case "Pensionierung":
        return mutationPensionierung(mutation);
      case "Todesfall":
        return mutationTodesfall(mutation);
      case "Eintritt":
        return mutationEintritt(mutation);
      case "Wiedereintritt":
        return mutationEintritt(mutation);

      default:
        return { content: [], more: [] };
    }
  } catch {
    return { content: [], more: [] };
  }
}

function mutationEintritt(mutation) {
  let content: MutationData[] = [];
  let more: MutationData[] = [];
  if (
    mutation?.attributes?.values?.employee?.entryDate !==
    mutation?.attributes?.valuesBefore?.employee?.entryDate
  ) {
    content.push({
      prevValue:
        parseDate(mutation?.attributes?.valuesBefore?.employee?.entryDate) ||
        "-",
      value:
        parseDate(mutation?.attributes?.values?.employee?.entryDate) || "-",
      title: "Versicherung Eintrittsdatum",
    });
  }

  return { content, more };
}
function mutationVersicherteninfos(mutation) {
  let content: MutationData[] = [];
  let more: MutationData[] = [];
  const name = mutation?.attributes?.values?.person?.name,
    nameBefore = mutation?.attributes?.valuesBefore?.person?.name;
  if (typeof name == typeof nameBefore && name != nameBefore) {
    content.push({
      prevValue: nameBefore || "-",
      value: name || "-",
      title: "Name",
    });
  }
  const firstname = mutation?.attributes?.values?.person?.firstname,
    firstnameBefore = mutation?.attributes?.valuesBefore?.person?.firstname;
  if (
    typeof firstname == typeof firstnameBefore &&
    firstname != firstnameBefore
  ) {
    const values = {
      prevValue: firstnameBefore || "-",
      value: firstname || "-",
      title: "Vorname",
    };
    content.length === 1 ? more.push(values) : content.push(values);
  }
  const birthdate = mutation?.attributes?.values?.person?.birthDate,
    birthdateBefore = mutation?.attributes?.valuesBefore?.person?.birthDate;
  if (typeof birthdate) {
    const birthDate = {
      prevValue:
        parseDate(mutation?.attributes?.valuesBefore?.person?.birthDate) || "-",
      value: parseDate(mutation?.attributes?.values?.person?.birthDate) || "-",
      title: "Geburtsdatum",
    };
    content.length === 1 ? more.push(birthDate) : content.push(birthDate);
  }
  if (
    mutation?.attributes?.values?.person?.socialSecurityNumber !==
    mutation?.attributes?.valuesBefore?.person?.socialSecurityNumber
  ) {
    const socialSecurityNumber = {
      prevValue:
        mutation?.attributes?.valuesBefore?.person?.socialSecurityNumber || "-",
      value: mutation?.attributes?.values?.person?.socialSecurityNumber || "-",
      title: "AHV-Nummer",
    };
    content.length === 1
      ? more.push(socialSecurityNumber)
      : content.push(socialSecurityNumber);
  }
  //Not in the pulled data from the backend
  if (
    mutation?.attributes?.values?.person?.costCenter !==
    mutation?.attributes?.valuesBefore?.person?.costCenter
  ) {
    const costCenter = {
      prevValue: mutation?.attributes?.valuesBefore?.person?.costCenter || "-",
      value: mutation?.attributes?.values?.person?.costCenter || "-",
      title: "Kostenstelle",
    };
    content.length === 1 ? more.push(costCenter) : content.push(costCenter);
  }
  //Not in the pulled data from the backend
  if (
    mutation?.attributes?.values?.person?.insurancePlan !==
    mutation?.attributes?.valuesBefore?.person?.insurancePlan
  ) {
    const insurancePlan = {
      prevValue:
        mutation?.attributes?.valuesBefore?.person?.insurancePlan || "-",
      value: mutation?.attributes?.values?.person?.insurancePlan || "-",
      title: "Versicherungsplan",
    };
    content.length === 1
      ? more.push(insurancePlan)
      : content.push(insurancePlan);
  }

  return { content, more };
}
function mutationAdresse(mutation) {
  let content: MutationData[] = [];
  let more: MutationData[] = [];
  console.log(
    mutation.attributes.values.person.name,
    typeof mutation?.attributes?.values?.address?.address2,
    typeof mutation?.attributes?.valuesBefore?.address?.address2
  );
  if (
    mutation?.attributes?.values?.address?.address1 !==
    mutation?.attributes?.valuesBefore?.address?.address1
  ) {
    content.push({
      prevValue: mutation?.attributes?.valuesBefore?.address?.address1 || "-",
      value: mutation?.attributes?.values?.address?.address1 || "-",
      title: "Adresszeile 1",
    });
  }
  if (
    mutation?.attributes?.values?.address?.address2 !=
    mutation?.attributes?.valuesBefore?.address?.address2
  ) {
    content.push({
      prevValue: mutation?.attributes?.valuesBefore?.address?.address2 || "-",
      value: mutation?.attributes?.values?.address?.address2 || "-",
      title: "Adresszeile 2",
    });
  }
  if (
    mutation?.attributes?.values?.address?.zipCode !==
    mutation?.attributes?.valuesBefore?.address?.zipCode
  ) {
    const zipCode = {
      prevValue: mutation?.attributes?.valuesBefore?.address?.zipCode || "-",
      value: mutation?.attributes?.values?.address?.zipCode || "-",
      title: "PLZ",
    };
    content.length === 1 ? more.push(zipCode) : content.push(zipCode);
  }
  if (
    mutation?.attributes?.values?.address?.city !==
    mutation?.attributes?.valuesBefore?.address?.city
  ) {
    const city = {
      prevValue: mutation?.attributes?.valuesBefore?.address?.city || "-",
      value: mutation?.attributes?.values?.address?.city || "-",
      title: "Ort",
    };
    content.length === 1 ? more.push(city) : content.push(city);
  }
  if (
    mutation?.attributes?.values?.address?.country !==
    mutation?.attributes?.valuesBefore?.address?.country
  ) {
    const country = {
      prevValue: mutation?.attributes?.valuesBefore?.address?.country || "-",
      value: mutation?.attributes?.values?.address?.country || "-",
      title: "Land",
    };
    content.length === 1 ? more.push(country) : content.push(country);
  }
  if (
    mutation?.attributes?.values?.address?.validFrom !==
    mutation?.attributes?.valuesBefore?.address?.validFrom
  ) {
    const validFrom = {
      prevValue:
        parseDate(mutation?.attributes?.valuesBefore?.address?.validFrom) ||
        "-",
      value: parseDate(mutation?.attributes?.values?.address?.validFrom) || "-",
      title: "Adresse gültig ab",
    };
    content.length === 1 ? more.push(validFrom) : content.push(validFrom);
  }

  return { content, more };
}
function mutationLohnaenderung(mutation) {
  let content: MutationData[] = [];
  let more: MutationData[] = [];
  if (
    mutation?.attributes?.values?.payroll?.grossSalary !==
    mutation?.attributes?.valuesBefore?.payroll?.grossSalary
  ) {
    const prevValue = mutation?.attributes?.valuesBefore?.payroll?.grossSalary;
    const value = mutation?.attributes?.values?.payroll?.grossSalary;
    content.push({
      prevValue: prevValue ? "CHF " + prevValue : "-",
      value: value ? "CHF " + value : "-",
      title: "Jahresbruttolohn",
    });
  }
  if (
    mutation?.attributes?.values?.payroll?.activityRate !==
    mutation?.attributes?.valuesBefore?.payroll?.activityRate
  ) {
    const prevValue = mutation?.attributes?.valuesBefore?.payroll?.activityRate;
    const value = mutation?.attributes?.values?.payroll?.activityRate;
    content.push({
      prevValue: prevValue ? prevValue + "%" : "-",
      value: value ? value + "%" : "-",
      title: "Pensum",
    });
  }
  if (
    mutation?.attributes?.values?.payroll?.validFrom !==
    mutation?.attributes?.valuesBefore?.payroll?.validFrom
  ) {
    const validFrom = {
      prevValue:
        parseDate(mutation?.attributes?.valuesBefore?.payroll?.validFrom) ||
        "-",
      value: parseDate(mutation?.attributes?.values?.payroll?.validFrom) || "-",
      title: "Gültig ab",
    };
    content.length === 1 ? more.push(validFrom) : content.push(validFrom);
  }

  return { content, more };
}
function mutationUnbezahlterUrlaub(mutation) {
  let content: MutationData[] = [];
  let more: MutationData[] = [];
  if (
    mutation?.attributes?.values?.unpaidVacation?.validFrom !==
    mutation?.attributes?.valuesBefore?.unpaidVacation?.validFrom
  ) {
    content.push({
      prevValue:
        parseDate(
          mutation?.attributes?.valuesBefore?.unpaidVacation?.validFrom
        ) || "-",
      value:
        parseDate(mutation?.attributes?.values?.unpaidVacation?.validFrom) ||
        "-",
      title: "Von",
    });
  }
  if (
    mutation?.attributes?.values?.unpaidVacation?.validUntil !==
    mutation?.attributes?.valuesBefore?.unpaidVacation?.validUntil
  ) {
    const validUntil = {
      prevValue:
        parseDate(
          mutation?.attributes?.valuesBefore?.unpaidVacation?.validUntil
        ) || "-",
      value:
        parseDate(mutation?.attributes?.values?.unpaidVacation?.validUntil) ||
        "-",
      title: "Bis",
    };
    content.length === 1 ? more.push(validUntil) : content.push(validUntil);
  }
  if (
    mutation?.attributes?.values?.unpaidVacation?.contributionCoverage !==
    mutation?.attributes?.valuesBefore?.unpaidVacation?.contributionCoverage
  ) {
    const contributionCoverage = {
      prevValue:
        mutation?.attributes?.valuesBefore?.unpaidVacation
          ?.contributionCoverage || "-",
      value:
        mutation?.attributes?.values?.unpaidVacation?.contributionCoverage ||
        "-",
      title: "Keine Beiträge",
    };
    content.length === 1
      ? more.push(contributionCoverage)
      : content.push(contributionCoverage);
  }

  return { content, more };
}
function mutationAustritt(mutation) {
  let content: MutationData[] = [];
  let more: MutationData[] = [];

  if (
    mutation?.attributes?.values?.employee?.quittingDate !==
    mutation?.attributes?.valuesBefore?.employee?.quittingDate
  ) {
    content.push({
      prevValue:
        parseDate(mutation?.attributes?.valuesBefore?.employee?.quittingDate) ||
        "-",
      value:
        parseDate(mutation?.attributes?.values?.employee?.quittingDate) || "-",
      title: "Austrittsdatum",
    });
  }
  if (
    mutation?.attributes?.values?.employee?.reasonForLeaving !==
    mutation?.attributes?.valuesBefore?.employee?.reasonForLeaving
  ) {
    content.push({
      prevValue:
        mutation?.attributes?.valuesBefore?.employee?.reasonForLeavingName ||
        "-",
      value:
        mutation?.attributes?.values?.employee?.reasonForLeavingName || "-",
      title: "Austrittsgrund",
    });
  }
  return { content, more };
}
function mutationInvaliditat(mutation) {
  let content: MutationData[] = [];
  let more: MutationData[] = [];

  //not coming from the backend
  if (
    mutation?.attributes?.values?.employee?.disabilityDate !==
    mutation?.attributes?.valuesBefore?.employee?.disabilityDate
  ) {
    content.push({
      prevValue:
        parseDate(
          mutation?.attributes?.valuesBefore?.employee?.disabilityDate
        ) || "-",
      value:
        parseDate(mutation?.attributes?.values?.employee?.disabilityDate) ||
        "-",
      title: "Invaliditäts Datum",
    });
  }
  if (
    mutation?.attributes?.values?.employee?.degreeDisability !==
    mutation?.attributes?.valuesBefore?.employee?.degreeDisability
  ) {
    const prevValue =
      mutation?.attributes?.valuesBefore?.employee?.degreeDisability;
    const value = mutation?.attributes?.values?.employee?.degreeDisability;
    content.push({
      prevValue: prevValue ? prevValue + "%" : "-",
      value: value ? value + "%" : "-",
      title: "Invaliditätsgrad",
    });
  }
  return { content, more };
}
function mutationZivilstandsanderung(mutation) {
  let content: MutationData[] = [];
  let more: MutationData[] = [];

  if (
    mutation?.attributes?.values?.person?.civilStatus !==
    mutation?.attributes?.valuesBefore?.person?.civilStatus
  ) {
    content.push({
      prevValue:
        mutation?.attributes?.valuesBefore?.person?.civilStatusName || "-",
      value: mutation?.attributes?.values?.person?.civilStatusName || "-",
      title: "Zivilstand",
    });
  }
  //not coming from the backend
  if (
    mutation?.attributes?.values?.person?.marriageDate !==
    mutation?.attributes?.valuesBefore?.person?.marriageDate
  ) {
    content.push({
      prevValue:
        parseDate(mutation?.attributes?.valuesBefore?.person?.marriageDate) ||
        "-",
      value:
        parseDate(mutation?.attributes?.values?.person?.marriageDate) || "-",
      title: "Heiratsdatum",
    });
  }
  if (
    mutation?.attributes?.values?.person?.divorceDate !==
    mutation?.attributes?.valuesBefore?.person?.divorceDate
  ) {
    const divorceDate = {
      prevValue:
        parseDate(mutation?.attributes?.valuesBefore?.person?.divorceDate) ||
        "-",
      value:
        parseDate(mutation?.attributes?.values?.person?.divorceDate) || "-",
      title: "Scheidungsdatum",
    };
    content.length === 1 ? more.push(divorceDate) : content.push(divorceDate);
  }
  return { content, more };
}
function mutationPensionierung(mutation) {
  let content: MutationData[] = [];
  let more: MutationData[] = [];

  if (
    mutation?.attributes?.values?.employee?.retirementDate !==
    mutation?.attributes?.valuesBefore?.employee?.retirementDate
  ) {
    content.push({
      prevValue:
        parseDate(
          mutation?.attributes?.valuesBefore?.employee?.retirementDate
        ) || "-",
      value:
        parseDate(mutation?.attributes?.values?.employee?.retirementDate) ||
        "-",
      title: "Pensionierungdatum",
    });
  }
  if (
    mutation?.attributes?.values?.employee?.degreeRetirement !==
    mutation?.attributes?.valuesBefore?.employee?.degreeRetirement
  ) {
    const prevValue =
      mutation?.attributes?.valuesBefore?.employee?.degreeRetirement;
    const value = mutation?.attributes?.values?.employee?.degreeRetirement;
    content.push({
      prevValue: prevValue ? prevValue + "%" : "-",
      value: value ? value + "%" : "-",
      title: "Teilpensionierung",
    });
  }
  return { content, more };
}
function mutationTodesfall(mutation) {
  let content: MutationData[] = [];
  let more: MutationData[] = [];

  if (
    mutation?.attributes?.values?.person?.dateOfDeath !==
    mutation?.attributes?.valuesBefore?.person?.dateOfDeath
  ) {
    content.push({
      prevValue:
        parseDate(mutation?.attributes?.valuesBefore?.person?.dateOfDeath) ||
        "-",
      value:
        parseDate(mutation?.attributes?.values?.person?.dateOfDeath) || "-",
      title: "Todesdatum",
    });
  }
  return { content, more };
}
// unmount these sentences when the unpaidVacation layout has changed
function unpaidVacationContributionValue(value: number) {
  switch (value) {
    case 1 || 3:
      return "Versicherter zahlt Arbeitgeber- und Arbeitnehmerbeiträge";
    case 2 || 4:
      return "Versicherter und Arbeitgeber zahlen jeweilige Beiträge";
    case 0:
      return "Versicherungsdeckung wird ausgesetzt";
    default:
      return "";
  }
}
